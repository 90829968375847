import moment from 'moment'
import ZbInfoModal from './zb-info-modal'
import ZbTableOrderModal from './zb-add-table-order-modal'
import ZbTableInfoModal from './zb-table-info-modal'
import { ServiceFactory } from '@/services/ServiceFactory'
import ZbMixinTableOrdersCompleted from './ZbMixinTableOrdersCompleted'
import ZbMixinTableOrdersCancelled from './ZbMixinTableOrdersCancelled'
const AreaTableService = ServiceFactory.get('AreaTableService')
const TableOrdersService = ServiceFactory.get('TableOrdersService')

export default {
  name: 'ZbTableOrders',
  components: {
    ZbInfoModal,
    ZbTableInfoModal,
    ZbTableOrderModal
  },
  mixins: [
    ZbMixinTableOrdersCompleted,
    ZbMixinTableOrdersCancelled
  ],
  data () {
    return {
      tab: null,
      showTableOrdersModal: false,
      showEnvironmentModal: false,
      showNewOrderModal: false,
      showInfoModal: false,
      showTableInfoModal: false,
      loading: false,
      areaTables: [],
      areaId: '',
      orderInfo: null,
      tableOrders: [],
      timerId: null,
      selected: [2],
      recentOrders: [],
      orderIds: [],
      timesReloaded: 0
    }
  },
  watch: {
    showEnvironmentModal (newVal, oldVal) {
      if (newVal === undefined) {
        this.initializeArea()
      }
    },
    showTableOrdersModal (newVal, oldVal) {
      if (newVal === undefined) {
        this.initializeArea()
      }
    }
  },
  mounted () {
    this.sync()
    this.timerId = setInterval(() => {
      this.sync()
    }, 60 * 1000)
  },
  methods: {
    async initializeArea () {
      try {
        this.loading = true
        const { success, barAreas } = await AreaTableService.get()
        this.loading = false
        if (success) {
          this.areaTables = barAreas
        }
      } catch (error) {
        this.loading = false
      }
    },
    async initializeRecentOrders () {
      try {
        this.loading = true
        const { success, orders } = await TableOrdersService.getRecent()
        this.loading = false
        if (success) {
          if (orders.length > 0 && this.timesReloaded === 0) {
            const ids = orders.map(e => e.zbOrderId)
            this.orderIds = ids
          }
          if (this.timesReloaded > 0) {
            const ids = orders.map(e => e.zbOrderId)
            for (let index = 0; index < ids.length; index++) {
              if (!this.orderIds.includes(ids[index])) {
                this.orderIds.push(ids[index])
                this.notificationSound()
              }
            }
          }
          this.timesReloaded += 1
          this.recentOrders = orders
        }
      } catch (error) {
        this.loading = false
      }
    },
    timeLeftOrder (dt) {
      const startdate = moment()
      const finishdate = moment(dt)
      const minutes = startdate.diff(finishdate, 'minutes')
      if (minutes === 0) return 'Hace segundos'
      return `${minutes} min.`
    },
    sync () {
      this.initializeArea()
      this.initializeRecentOrders()
    },
    openInfoOrder (data) {
      if (!data) return
      this.orderInfo = data
      this.showInfoModal = true
    },
    openOrderForm () {
      this.showNewOrderModal = true
    },
    openTableInfo (orders) {
      if (orders.length === 0) return
      this.tableOrders = orders
      this.showTableInfoModal = true
    },
    handleReload () {
      this.sync()
    },
    notificationSound () {
      var audio = new Audio(`${process.env.BASE_URL}audio/new-order.mp3`)
      audio.play()
    }
  },
  beforeDestroy () {
    clearInterval(this.timerId)
  }
}
