import { ServiceFactory } from '@/services/ServiceFactory'
const TableOrdersService = ServiceFactory.get('TableOrdersService')

export default {
  data () {
    return {
      dialogCompleted: false,
      provisionalIdBooking: null,
      provitionalAction: null,
      singleSelect: false,
      loadingDelivered: false,
      publicPath: `${process.env.BASE_URL}images/user-default.png`,
      selected: [],
      headersDelivered: [
        { text: this.$t('tableOrders.image'), sortable: false, value: 'zbClient.image' },
        { text: this.$t('tableOrders.name'), sortable: false, value: 'zbClient.name' },
        { text: this.$t('tableOrders.tableNumber'), align: 'start', sortable: false, value: 'numberTable' },
        { text: this.$t('tableOrders.totalOrder'), align: 'start', sortable: false, value: 'order.totalBill' },
        { text: this.$t('general.createdAt'), sortable: false, value: 'createdAt' },
        { text: this.$t('tableOrders.moreInfo'), sortable: false, value: 'order' }
      ],
      ordersDelivered: [],
      itemsPerPageDelivered: 15,
      pageDelivered: 1,
      optionsDelivered: {
        success: false,
        totalItems: 0,
        totalPages: 0,
        currentPage: 0
      }
    }
  },
  watch: {
    tab () {
      if (this.tab === 1) {
        this.initializeTableOrdersDelivered()
      }
    },
    pageDelivered () {
      this.initializeTableOrdersDelivered()
    },
    itemsPerPageDelivered () {
      this.initializeTableOrdersDelivered()
    }
  },
  methods: {
    async initializeTableOrdersDelivered () {
      this.loadingDelivered = true
      const { orders, success, totalItems, totalPages, currentPage } = await TableOrdersService.get(this.pageDelivered, 'delivered', this.itemsPerPageDelivered)
      this.loadingDelivered = false
      this.ordersDelivered = orders
      this.optionsDelivered = {
        success,
        totalItems,
        totalPages,
        currentPage
      }
    }
  }
}
