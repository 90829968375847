import { ServiceFactory } from '@/services/ServiceFactory'
import moment from 'moment'
const TableOrdersService = ServiceFactory.get('TableOrdersService')

export default {
  name: 'ZbTableInfoModal',
  data () {
    return {
      info: null,
      publicPath: `${process.env.BASE_URL}images/user-default.png`,
      infoData: null,
      acceptLoading: false,
      cancelLoading: false,
      fullscreen: false,
      headers: [
        {
          text: 'Nombre',
          align: 'Cantidad',
          value: 'zbClient.name'
        },
        {
          text: 'Hora',
          align: 'start',
          filterable: false,
          value: 'createdAt'
        },
        {
          text: 'Estado',
          align: 'start',
          filterable: false,
          value: 'stateText'
        },
        {
          text: 'Total',
          align: 'start',
          filterable: false,
          value: 'order.totalBill'
        },
        {
          text: 'Acciones',
          align: 'start',
          filterable: false,
          value: 'actions'
        }
      ]
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    orders: {
      type: Array
    }
  },
  mounted () {
    this.modalResponsive()
  },
  methods: {
    async changeState (id, state) {
      try {
        this.acceptLoading = true
        const { success } = await TableOrdersService.put(id, { orderState: state })
        this.acceptLoading = false
        if (success) {
          this.$emit('reload')
          this.$emit('input')
        }
      } catch (error) {
        this.acceptLoading = false
      }
    },
    async cancelOrder (id) {
      this.$swal({
        title: this.$t('tableOrders.cancelQuestion'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('delete.confirm'),
        cancelButtonText: this.$t('delete.cancel')
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            this.cancelLoading = true
            const { success } = await TableOrdersService.delete(id, { orderState: 'cancelled' })
            this.cancelLoading = false
            if (success) {
              this.$emit('input')
              this.$emit('reload')
            }
          } catch (error) {
            this.cancelLoading = false
          }
        }
      })
    },
    timeLeftOrder (dt) {
      const startdate = moment()
      const finishdate = moment(dt)
      const minutes = startdate.diff(finishdate, 'minutes')
      if (minutes === 0) return 'Hace segundos'
      return `${minutes} min.`
    },
    dialogEvent () {
      this.$emit('input')
    },
    modalResponsive () {
      const mediumBp = matchMedia('(min-width: 660px)')
      const changeSize = mql => {
        mql.matches
          ? this.fullscreen = false
          : this.fullscreen = true
      }
      mediumBp.addListener(changeSize)
      changeSize(mediumBp)
    },
    printOrder (id) {
      this.$htmlToPaper(id)
    }
  }
}
