import { ServiceFactory } from '@/services/ServiceFactory'
const ProductService = ServiceFactory.get('ProductService')
const AreaTableService = ServiceFactory.get('AreaTableService')
const TableOrdersService = ServiceFactory.get('TableOrdersService')

export default {
  name: 'ZbTableOrder',
  data () {
    return {
      info: null,
      publicPath: `${process.env.BASE_URL}images/user-default.png`,
      acceptLoading: false,
      cancelLoading: false,
      isSubmiting: false,
      formState: 'cart',
      fullscreen: false,
      search: '',
      menu: [],
      tables: [],
      paymentMethods: [{ label: 'Pago en efectivo', value: 'physical' }],
      headers: [
        {
          text: 'Nombre',
          align: 'Cantidad',
          value: 'name'
        },
        {
          text: 'Precio',
          align: 'start',
          filterable: false,
          value: 'price'
        },
        {
          text: 'Cantidad',
          align: 'start',
          filterable: false,
          value: 'actions'
        },
        {
          text: 'Total',
          align: 'start',
          filterable: false,
          value: 'total'
        }
      ],
      orderData: {
        zbTableNumber: null,
        name: '',
        comments: '',
        order: {
          items: [],
          totalBill: 0,
          totalTickets: 0,
          billingAccount: '',
          billingName: '',
          paymentMethod: 'physical'
        }
      }
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    orderInfo: {
      type: Object
    }
  },
  mounted () {
    this.modalResponsive()
    this.getMenu()
    this.getTables()
    this.getTotal()
  },
  methods: {
    async getMenu () {
      try {
        this.acceptLoading = true
        const { success, products } = await ProductService.getProducts('product')
        this.acceptLoading = false
        if (success) {
          let counterAdded = products.map(e => { return { ...e, counter: 0 } })
          this.menu = counterAdded
        }
      } catch (error) {
        this.acceptLoading = false
      }
    },
    async getTables () {
      try {
        const { success, tables } = await AreaTableService.getTables()
        if (success) {
          let labelAdded = tables.map(e => { return { ...e, numberTable: String(e.numberTable), label: `Mesa Nro. ${e.numberTable}` } })
          this.tables = labelAdded
        }
      } catch (error) {
        this.acceptLoading = false
      }
    },
    async save () {
      try {
        this.isSubmiting = true
        let items = []
        for (let index = 0; index < this.menu.length; index++) {
          if (this.menu[index].counter > 0) {
            items.push({
              name: this.menu[index].name,
              price: this.menu[index].price,
              quantity: this.menu[index].counter
            })
          }
        }
        this.orderData.order.items = items
        this.orderData.order.billingName = this.orderData.name
        const { success, msg } = await TableOrdersService.post(this.orderData)
        this.isSubmiting = false
        if (success) {
          this.$notify({
            title: 'Éxito',
            type: 'success',
            text: msg
          })
          this.formState = 'cart'
          this.orderData = {
            zbTableNumber: null,
            name: '',
            comments: '',
            order: {
              items: [],
              totalBill: 0,
              totalTickets: 0,
              billingAccount: '',
              billingName: '',
              paymentMethod: 'physical'
            }
          }
          let counterAdded = this.menu.map(e => { return { ...e, counter: 0 } })
          this.menu = counterAdded
          this.dialogEvent()
          this.$emit('reload')
        }
      } catch (error) {
        this.isSubmiting = false
        this.$notify({
          title: 'Error',
          type: 'error',
          text: error.msg
        })
      }
    },
    increaseCart (item) {
      item.counter += 1
      this.getTotal()
    },
    decreaseCart (item) {
      if (item.counter === 0) return
      item.counter -= 1
      this.getTotal()
    },
    getTotal () {
      if (this.menu.length === 0) return 0
      let total = 0.0
      for (let index = 0; index < this.menu.length; index++) {
        total += this.menu[index]['price'] * this.menu[index]['counter']
      }
      this.orderData.order.totalBill = total
    },
    toggleForm (state) {
      this.formState = state
    },
    dialogEvent () {
      this.$emit('input')
    },
    modalResponsive () {
      const mediumBp = matchMedia('(min-width: 660px)')
      const changeSize = mql => {
        mql.matches
          ? this.fullscreen = false
          : this.fullscreen = true
      }
      mediumBp.addListener(changeSize)
      changeSize(mediumBp)
    }
  }
}
